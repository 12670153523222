<template>
  <div>
    <div class="flex">
      <ms-input
        class="font16"
        v-model="val"
        :label="label"
        :placeholder="`Enter ${label}`"
        :validator="v" />

      <div class="ml-10 align-bottom">
        <el-button
          type="success"
          :loading="validating"
          :disabled="v.$invalid"
          round
          @click="validate()">
          Validate
        </el-button>
      </div>
    </div>

    <template v-if="bankInfo">
      <p class="bank-data">{{ bankNameText }}&nbsp;</p>
      <p v-if="!bankAddress" class="error-message">No Address Provided</p>
      <template v-if="bankAddress">
        <p>{{ bankAddressText }}</p>
        <p>{{ bankLocationText }}</p>
      </template>
    </template>

    <ms-drawer
      v-if="showConfirm"
      title="Bank Confirmation"
      @close="showConfirm = false">
      <p v-if="bankError">Error searching Banks. {{ bankError }}</p>
      <p v-if="notFound">Intermediary Bank not found. Please check information and try again.</p>
      <data-list v-if="bankInfo" :list="bankConfirmationData" />
      <template slot="footer">
        <div class="buttons">
          <el-button @click="showConfirm = false">
            {{ notFound || bankError ? 'Close' : 'Cancel' }}
          </el-button>

          <el-button
            v-if="!notFound && !bankError && showConfirmButton"
            type="success"
            class="ml-10"
            @click="confirm()">
            Confirm
          </el-button>
        </div>
      </template>
    </ms-drawer>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: String,
      default: null
    },

    v: {
      type: Object,
      default: null
    },

    type: {
      type: String,
      required: true
    },

    action: {
      type: String,
      required: true
    },

    label: {
      type: String,
      required: true
    },

    showConfirmButton: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      bankName: null,
      bankInfo: null,
      bankAddress: null,

      showConfirm: false,
      validating: false,

      bankError: null,
      notFound: false
    }
  },

  computed: {
    val: {
      get () {
        return this.value
      },

      set (value) {
        if (this.v) this.v.$touch()
        this.$emit('input', value)
      }
    },

    bankNameText () {
      if (!this.bankInfo) return null

      let text = this.bankName
      if (this.bankInfo?.attributes?.['phone-number-id']) {
        text += `, ${this.bankInfo.attributes['phone-number-id']}`
      }
      return text
    },

    bankAddressText () {
      if (!this.bankAddress) return null

      let text = ''
      if (this.bankAddress['street-1']) {
        text = this.bankAddress['street-1']
        if (this.bankAddress['street-2']) {
          text += `, ${this.bankAddress['street-2']}`
        }
      }

      if (this.bankAddress.line1) {
        text = this.bankAddress.line1
        if (this.bankAddress.line2) {
          text += `, ${this.bankAddress.line2}`
        }
      }

      return text
    },

    bankLocationText () {
      if (!this.bankAddress) return null

      const postal = this.bankAddress['postal-code'] || this.bankAddress.postalCode
      return `${this.bankAddress.city}, ${this.bankAddress.region || this.bankAddress.state}, ${postal}, ${this.bankAddress.country}`
    },

    bankConfirmationData () {
      if (!this.bankInfo) return

      if(this.type == 'routingNumber') {
        return [
          {
            label: 'Bank Routing Number',
            text: this.bankInfo.routingNumber
          },
          {
            label: 'Bank Name',
            text: this.bankInfo.name
          },
          {
            label: 'Bank Address',
            text: this.bankInfo.street
          },
          {
            label: 'Bank Address 2',
            text: this.bankAddress.line2
          },
          {
            label: 'Bank City',
            text: this.bankInfo.city
          },
          {
            label: 'Bank State/Province',
            text: this.bankInfo.state
          },
          {
            label: 'Bank Postal/Zip',
            text: this.bankInfo.zip
          },
          {
            label: 'Bank Country',
            text: this.bankInfo.country
          },
          {
            label: 'Bank Phone',
            text: this.bankInfo.phone
          }
        ]
      } else {
        return [
          {
            label: 'Bank Swift Code',
            text: this.val
          },
          {
            label: 'Bank Name',
            text: this.bankAddress.name
          },
          {
            label: 'Bank Address',
            text: this.bankAddress.address
          },
          {
            label: 'Bank Address 2',
            text: this.bankAddress.line2
          },
          {
            label: 'Bank City',
            text: this.bankAddress.city
          },
          {
            label: 'Bank State/Province',
            text: this.bankAddress.state
          },
          {
            label: 'Bank Postal/Zip',
            text: this.bankAddress.zip
          },
          {
            label: 'Bank Country',
            text: this.bankAddress.country
          },
          {
            label: 'Bank Phone',
            text: this.bankAddress.phone
          }
        ]
      }
      
    }
  },

  methods: {
    async validate () {
      if (this.v) {
        this.v.$touch()
        if (this.v.$invalid) return
      }

      this.validating = true
      this.reset()

      try {
        const payload = {
          type: this.type,
          routingNumber: this.val,
          swiftCode: this.val
        }
        const { action } = await this.$store.dispatch('transactions/action', {
          id: 'new',
          action: this.action,
          payload
        })

        if (action.error) {
          this.bankError = action.errors || null
        } else if (action.notFound) {
          this.notFound = true
        } else {
          if (this.type == 'routingNumber') {
            this.bankInfo = action.data
            this.bankAddress = action.address
          } else {
            this.bankInfo = action.data.swift_data
            this.bankAddress = action.data.bank_data
          }
            
        }
        this.showConfirm = true
      } catch (e) {
        console.error(e)
        this.$notify(window.errorMessage)
      } finally {
        this.validating = false
      }
    },

    reset () {
      this.notFound = false
      this.bankError = null
      this.bankInfo = null
      this.bankAddress = null
      this.showConfirm = false
    },

    confirm () {
      this.bankName = this.bankInfo?.attributes?.name || this.bankInfo?.bankName

      if (this.bankAddress) {
        this.$emit('confirm', {
          ...this.bankAddress,
          name: this.bankName
        })
      }

      this.showConfirm = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .align-bottom {
    padding-top: 5px;
  }
</style>
